import React from "react";

// Animations
import { motion } from "framer-motion";
import { pageAnim } from "../animation";

const About = () => {
  const HoverAnim = {
    hover_anim: {
      backgroundColor: "rgb(0, 0, 0)",
      color: "#e9e9e9",
      transition: { duration: 0.0 },
    },
  };

  return (
    <motion.div
      className="pages-about"
      variants={pageAnim}
      initial="hidden"
      exit="exit"
      animate="show"
    >
      <div className="about-contact">
        <div className="about">
          <div className="title">
            <h1>ABOUT</h1>
          </div>
          <div className="text-body">
            <p>
              Pauric Freeman is a new media artist working with sound, video,
              and installations. He develops visual systems that respond live to
              the varying rhythms and textures in his music, exploring the
              realtionships between sound and visuals. Through merging art and
              technology, his work experiments with new approaches to
              audiovisual performance.
            </p>
            <br></br>
            <p>
              Currently based in Dublin, Pauric’s work has been exhibited and
              performed in a wide range of venues and festivals, both in Ireland
              and internationally in Europe, North America, and Australia. He
              has collaborated with a diverse range of artists, and his work has
              been recognized for its innovative approach to audiovisual
              performance.
            </p>
            <br></br>
            <p>
              As a freelance creative developer, Pauric works in the design and
              programming of installations, as well as developing applications
              for mobile, desktop, VR, and web. He is a lecturer at the
              Victorian College of the Arts at the University of Melbourne,
              where he teaches audiovisual and interactive media design.
            </p>
            <br></br>
            <br></br>
          </div>

          <div className="text-body">
            <h6>
              <motion.a
                href="https://derivative.ca/community-post/pauric-freeman-working-sound-image-input-devices-and-human-perception/65814"
                target="blank"
                variants={HoverAnim}
                whileHover="hover_anim"
              >
                Interview with TouchDesigner
              </motion.a>
            </h6>
            <h6>
              <motion.a
                href="https://www.generativehut.com/post/interview-with-pauric-freeman#:~:text=Pauric%3A%20My%20name%20is%20Pauric,media%20artist%20based%20in%20Ireland.&text=As%20a%20freelance%20designer%20I,University%20of%20Melbourne%20in%20Australia."
                target="blank"
                variants={HoverAnim}
                whileHover="hover_anim"
              >
                Interview with Generative Hut
              </motion.a>
            </h6>
          </div>
        </div>

        <div className="contact">
          <div className="title">
            <h1>CONTACT</h1>
          </div>
          <div className="text-body">
            <h6>
              Project enquieries:{" "}
              <motion.a
                href="mailto: hello@pauricfreeman.com"
                variants={HoverAnim}
                whileHover="hover_anim"
              >
                hello@pauricfreeman.com
              </motion.a>
            </h6>
            <p>
              Bookings:{" "}
              <motion.a
                href="mailto: info@pauricfreeman.com"
                variants={HoverAnim}
                whileHover="hover_anim"
              >
                info@pauricfreeman.com
              </motion.a>
            </p>
            <p>
              Social:{" "}
              <motion.a
                href="https://www.instagram.com/pauric_freeman"
                target="_blank"
                variants={HoverAnim}
                whileHover="hover_anim"
              >
                @pauric_freeman
              </motion.a>
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
